.Comp_Video{
    width: 80%;
    height: calc(100% - 300px);
    margin: 0% auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    gap: 20px;
    color: #ffffff;
    overflow: hidden;

    animation-duration: 3s;
    animation-name: Comp_Video;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    padding: 150px 0px;
}
@keyframes Comp_Video {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.Comp_Video .efect_translate_y{
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
.Comp_Video .efect_translate_visible {
    opacity: 1;
    transform: translateY(0);
}

.Comp_Video .div_text{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Comp_Video .div_text .show_text{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.Comp_Video .div_text .show_text .subtitle{
    font-weight: 100;
    letter-spacing: 2px;
    font-size: 13px;
    text-transform: uppercase;
    padding: 0px 15px;
    color: rgba(255, 255, 255, 0.49);
    margin-bottom: 30px;
    text-align: center;
}
.Comp_Video .div_text .show_text .title{
    width: auto;
    max-width: 950px;
    margin: 0% auto;
    color: #ededed;
    font-size: 64px;
    text-align: left;
    padding: 0px 15px;
    text-align: center;
    font-size: 100px;

    font-style: normal;
    font-weight: 600;
    line-height: 110px;
    margin-top: 20px;
    margin-bottom: 69px;
}
.Comp_Video .div_text .show_text .text{
    font-size: 18px;
    font-weight: 100;
}


.Comp_Video .list_video{
    width: 100%;
    height: auto;
    margin: 0% auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Comp_Video .list_video .show_video{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 1000px) {
    .Comp_Video{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
    }
    .Comp_Video .div_text{
        width: 100%;
        height: auto;
    }
    .Comp_Video .list_video{
        width: 100%;
        height: auto;
        justify-content: flex-start;
    }
    .Comp_Video .list_video .show_video{
        height: auto;
        width: 90%;
    }
}
@media only screen and (max-width: 850px) {
    .Comp_Video{
        gap: 30px;
        width: 100%;
        padding: 80px 0px;
    }
    .Comp_Video .list_video .show_video{
        width: 100%;
    }
    .Comp_Video .div_text .show_text .title{
        width: 96%;
        margin: 0% auto;
        font-size: 55px;
        line-height: 70px;
        margin-bottom: 35px;
    }
}
@media only screen and (max-width: 760px) {
    .Comp_Video .div_text .show_text{
        gap: 10px;
    }
    .Comp_Video .div_text .show_text .text{
        font-size: 14px;
    }
}
@media only screen and (max-width: 510px) {
    .Comp_Video{
        gap: 30px;
    }
    .Comp_Video .div_text .show_text .subtitle{
        padding: 0;
    }
    .Comp_Video .div_text .show_text .title{
        font-size: 55px;
        line-height: 70px;
        width: auto;
        padding: 0;
    }
    .Comp_Video .list_video .show_video{
        height: auto;
        width: 100%;
    }
}