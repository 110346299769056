.Comp_Home{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
}
.Comp_Home .section{
    width: 100%;
    height: 100%;
}

.Comp_Home .div_text{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 8;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Comp_Home .div_text .show_title{
    width: 80%;
    font-family: unset;
    line-height: 69px;
    letter-spacing: 0.694444px;
    font-weight: 600;
    font-size: 76px;
    margin-bottom: 0;
    text-transform: none;
}
.Comp_Home .div_text .show_text{
    width: 80%;
    font-size: 10px;
    transition: 1s;
    cursor: default;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.5);
    padding-top: 30px;
    letter-spacing: 0.694444px;
    line-height: 14px;
    font-weight: 400;
}

.Comp_Home .div_video_or_img{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.Comp_Home .div_video_or_img .dotted {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 6;
    
    background-image: -webkit-repeating-radial-gradient(center center, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2) 1px, transparent 1px, transparent 100%);
    -webkit-background-size: 3px 3px;
    -moz-background-size: 3px 3px;
    background-size: 3px 3px;
    background-color: rgb(0 0 0 / 50%);
}
.Comp_Home .div_video_or_img .show_full{
    position: relative;
    background-size: cover;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
}
.Comp_Home .div_video_or_img .div_iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transform: scale(1.5);
}


.Comp_Home .div_video_or_img .show_rede_social{
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.Comp_Home .div_video_or_img .show_rede_social .list_data_rede_social{
    width: 80%;
    margin: 0% auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 40px;
}
.Comp_Home .div_video_or_img .show_rede_social .list_data_rede_social .name_click{
    font-family: sans-serif;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    line-height: 21px;
    font-weight: 700;
    font-size: 10px;
    border-color: rgba(255, 255, 255, 0.2);
    border-style: solid;
    border-width: 0px 0px 1px
}
.Comp_Home .div_video_or_img .show_rede_social .list_data_rede_social .div_rede_social{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.Comp_Home .div_video_or_img .show_rede_social .list_data_rede_social .div_rede_social .div_icons_social .icons_rede{
    width: 26px;
    height: auto;
    cursor: pointer;
}

@media only screen and (max-width: 860px) {
    .Comp_Home .div_video_or_img .div_iframe {
        transform: scale(4.5);
    }
}
@media only screen and (max-width: 860px) {
    .Comp_Home .div_text .show_title{
        font-size: 70px;
        line-height: 64px;
    }
}
@media only screen and (max-width: 510px) {
    .Comp_Home .div_text .show_title{
        font-size: 46px;
        line-height: 48px;
    }
    .Comp_Home .div_video_or_img .div_iframe{
        transform: scale(4.5);
    }
}


/* video */
.video_background {
    background: #000;
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -99;
}
.video_foreground, .video_background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    scale: 1;
}
@media (min-aspect-ratio: 16/9) {
    .video_foreground { 
        height: 300%; 
        top: -100%; 
    }
}
@media (max-aspect-ratio: 16/9) {
    .video_foreground { 
        width: 300%; 
        left: -100%; 
    }
}
@media all and (max-width: 600px) {
    .vid-info { 
        width: 50%; 
        padding: .5rem; 
    }
    .vid-info h1 { 
        margin-bottom: .2rem; 
    }
}
@media all and (max-width: 500px) {
    .vid-info .acronym {
        display: none;
    }
}
@media all and (max-width: 460px) {
    .video_foreground, .video_background iframe {
        scale: 1.5;
    }
    .Comp_Home .div_text .show_title br, .Comp_Home .div_text .show_text br{
        display: none;
    }
}
/* end */