.PopUp .icons_return{
    width: 200px;
    height: 200px;
    cursor: default;
}
.PopUp .title_return{
    font-size: 16px;
    font-family: 'Archivo';
    color: #ffffff;
}
.PopUp .close_return{
    background-color: #E1005A;
    color: #ffffff;
    width: 300px;
    padding: 10px 0px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Archivo';
    cursor: pointer;
    margin-top: 20px;
    border-radius: 6px;
}
