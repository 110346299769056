.Comp_Footer {
    width: 100%;
    height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;

    background-color: #121212;
}

.Comp_Footer .div_color_line{
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 180px;
}
.Comp_Footer .line_top{
    width: 100%;
    height: 120px;
    min-height: 120px;
    display: flex;
    flex-direction: row;
    position: absolute;
    z-index: 2;
    top: 0;
}
.Comp_Footer .line_top .line_ft_1{
    width: 25%;
    height: 100%;
    background-color: #121212;
}
.Comp_Footer .line_top .line_ft_2{
    width: 25%;
    height: 60%;
    background-color: #121212;
}
.Comp_Footer .line_top .line_ft_3{
    width: 25%;
    height: 40%;
    background-color: #121212;
}
.Comp_Footer .line_top .line_ft_4{
    width: 25%;
    height: 60%;
    background-color: #121212;
}
.Comp_Footer .line_bottom{
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: absolute;
    z-index: 2;
    bottom: 0;
}
.Comp_Footer .line_bottom .line_ft_1{
    width: 25%;
    height: 100%;
    background-color: #121212;
}
.Comp_Footer .line_bottom .line_ft_2{
    width: 25%;
    height: 94%;
    background-color: #121212;
}
.Comp_Footer .line_bottom .line_ft_3{
    width: 25%;
    height: 90%;
    background-color: #121212;
}
.Comp_Footer .line_bottom .line_ft_4{
    width: 25%;
    height: 94%;
    background-color: #121212;
}

.Comp_Footer .div_img{
    width: 100%;
    height: calc(100% - 210px);
    /* position: absolute; */
    cursor: pointer;
    background-color: #000000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.Comp_Footer .div_img .project{
    position: absolute;
    z-index: 2;
    left: 25%;
    padding: 20px;
    margin-top: 16%;
    color: #ffffff;
    font-size: 22px;
}
.Comp_Footer .div_img .project_subtitle{
    position: absolute;
    z-index: 2;
    left: 25%;
    padding: 20px;
    margin-top: 20%;
    color: #ffffff;
    font-size: 16px;
}
.Comp_Footer .div_img .show_preview{
    cursor: pointer;
}
.Comp_Footer .div_img .img{
    position: absolute;
    width: 130%;
    height: auto;
    left: 50%;
    transform: translate(-50%, -24%);
    object-fit: cover;
}
.Comp_Footer .div_img .img_logo{
    position: absolute;
    width: 30%;
    height: auto;
    /* left: 50%;
    transform: translate(-50%, -14%); */
    object-fit: cover;
}
.Comp_Footer .div_img .align_logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.Comp_Footer .div_img .div_btn{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    bottom: 30px;
    z-index: 2;
}
.Comp_Footer .div_img .div_btn .btn{  
    width: max-content;  
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 6px;
    background-color: #87C232;
    cursor: pointer;
}

.Comp_Footer .div_data_footer{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 60px;
    width: 80%;
    height: auto;
    flex-grow: 1;
    margin: 0% auto;
    position: absolute;
    z-index: 3;
    bottom: 0;
}
.Comp_Footer .div_data_footer .div_data_rede_social{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 16px;
    color: #ededed;
}
.Comp_Footer .div_data_footer .div_data_rede_social .show_rede_social{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
}

.Comp_Footer .show_data{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: 1s;
}
.Comp_Footer .show_data .div_btn{
    width: 46%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}   
.Comp_Footer .show_data .div_btn .title{
    font-size: 31px;
    color: #ffffff;
    line-height: 32px;
    margin-bottom: 16px;
    transition: 1s;
}
.Comp_Footer .show_data .div_btn .btn{  
    width: max-content;  
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 6px;
    background-color: #87C232;
    cursor: pointer;
}
.Comp_Footer .show_data .div_name_page .title_click{
    font-size: 14px;
    color: #707070;
}
.Comp_Footer .show_data .div_name_page .name_page{
    color: #ffffff;
    font-size: 21px;
}

.Comp_Footer .div_data_footer .div_data_rede_social .origin{
    text-align: left;
}
.Comp_Footer .div_data_footer .div_data_rede_social .origin_center{
    text-align: center;
}

.Comp_Footer .width_fixed{
    width: 230px;
}

@media only screen and (max-width: 850px) {
    .Comp_Footer .line_top .line_ft_1, .Comp_Footer .line_top .line_ft_2{
        width: 50%;
    }
    .Comp_Footer .line_bottom .line_ft_1, .Comp_Footer .line_bottom .line_ft_2{
        width: 50%;
    }
    .Comp_Footer .line_ft_3, .Comp_Footer .line_ft_4{
        display: none !important;
    }
    .Comp_Footer .div_img .img{
        transform: translate(-50%, 0%);
    }
}

@media only screen and (width: 820px) {
    .Comp_Footer .div_img .img{
        width: 100vw;
        transform: translate(-50%, 24%);
    }
}

@media only screen and (width: 768px) {
    .Comp_Footer .div_img .img{
        width: 100vw;
        transform: translate(-50%, 24%);
    }
}
@media only screen and (max-width: 680px) {
    .Comp_Footer .div_color_line .line_bottom{
        height: calc(100% - 220px);
    }
    .Comp_Footer .show_data{
        flex-direction: column;
        gap: 20px;
    }
    .Comp_Footer .div_name_page{
        text-align: center;
    }
    .Comp_Footer .show_data .div_btn{
        width: 100%;
        align-items: center;
        text-align: center;
    }
    .Comp_Footer .show_data .div_btn .title{
        font-size: 24px;
    }
    .Comp_Footer .show_data .div_name_page .name_page{
        font-size: 18px;
    }
}
@media only screen and (max-width: 670px) {
    .Comp_Footer .line_top{
        height: 60px;
        min-height: 60px;
    }
    .Comp_Footer .div_data_footer{
        gap: 40px;
    }
    .Comp_Footer .div_data_footer .div_data_rede_social{
        flex-direction: column;
        gap: 20px;
    }
    .Comp_Footer .width_fixed{
        width: auto;
    }
    .Comp_Footer .div_color_line{
        gap: 100px;
    }
}
@media only screen and (max-width: 580px) {
    .Comp_Footer{
        height: 800px;
    }
    .Comp_Footer .div_color_line{
        gap: 80px;
    }
    .Comp_Footer .div_img .img{
        width: 120%;
        transform: translate(-50%, 20%);
    }
    .Comp_Footer .show_data .div_btn .title{
        font-size: 26px;
        line-height: 30px;
    }
    .Comp_Footer .show_data .div_name_page .name_page{
        font-size: 12px;
    }
    .Comp_Footer .div_data_footer .div_data_rede_social .origin, .Comp_Footer .div_data_footer .div_data_rede_social .origin_center{
        text-align: center;
    }
    .Comp_Footer .div_data_footer{
        gap: 20px;
    }
    .Comp_Footer .line_top{
        height: 120px;
        min-height: 120px;
    }
    .Comp_Footer .line_bottom{
        height: 456px;
    }
    .Comp_Footer .div_img .project{
        margin-top: 30%;
    }
    .Comp_Footer .div_img .project_subtitle{
        margin-top: 36%;
    }
    .Comp_Footer .div_img{
        height: calc(100% - 330px);
    }
    .Comp_Footer .div_img .img_logo{
        width: 64%;
    }
    .Comp_Footer .line_bottom{
        bottom: -3px;
    }
}