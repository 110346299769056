.Comp_Spotlight {
    width: 100%;
    height: calc(100% - 150px);
    display: flex;
    flex-direction: column;
    position: relative;
    color: #ffffff;
    overflow: hidden;

    animation-duration: 3s;
    animation-name: Comp_Spotlight;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;

    padding-top: 150px;
}
@keyframes Comp_Spotlight {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.Comp_Spotlight .efect_translate_y{
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
.Comp_Spotlight .efect_translate_visible {
    opacity: 1;
    transform: translateY(0);
}

.Comp_Spotlight .show_spotlight{
    width: 100%;
    height: 100vh;
    position: relative;
    min-height: 750px;
}

.Comp_Spotlight .show_title .div_title{
    width: 80%;
    margin: 0% auto;
    padding-bottom: 60px;
    text-transform: uppercase;
}
.Comp_Spotlight .show_title .div_title .title_page{
    font-weight: 100;
    letter-spacing: 2px;
    font-size: 13px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.49);
}
.Comp_Spotlight .show_title .div_title .subtitle_page{
    font-weight: 600;
    font-style: normal;
    font-size: 47px;
    line-height: 65px;
}


/* carousel */
.Comp_Spotlight .carousel{
    overflow: hidden;
}
.Comp_Spotlight .div_gallery {
    width: 100%;
    height: auto;
    margin: 0% auto;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 120px;
    position: relative;
}
.Comp_Spotlight .div_img{
    height: 100%;
    position: relative;
}
.Comp_Spotlight .name{
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
}
.Comp_Spotlight .biography{
    color: #FFF;
    font-size: 12px;
    font-style: italic;
    text-align: justify;
}
.Comp_Spotlight .area{
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
}
.Comp_Spotlight .date{
    color: #895F55;
    font-size: 12px;
}
.Comp_Spotlight .show_click_img{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}
.Comp_Spotlight .show_img {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 50px;
    /* margin-top: 50px; */
    width: 100%;
    position: relative;
    min-height: 300px;
}
.Comp_Spotlight .div_carousel {
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 750px;
}
.Comp_Spotlight .gallery__stream {
    position: relative;
    width: 100%;
    height: 100%;
}
.Comp_Spotlight .show_gallery_data{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: 100%;
}
.Comp_Spotlight .gallery__item {
    position: absolute;
    height: 100%;
    transition: 1s all ease;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 5px;
    width: 320px;
    min-width: 320px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    cursor: pointer;
}

.Comp_Spotlight .gallery__item:nth-child(1) {
    left: -33.3%;
    z-index: 1;
    opacity: 0.3;
    width: 33.3%;
    min-width: 33.3%;
    max-width: 33.3%;
    transition: 1s;
}
.Comp_Spotlight .gallery__item:nth-child(1) .img_example{
    width: auto;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    transition: 1s;
}

.Comp_Spotlight .gallery__item:nth-child(2) {
    left: 0;
    z-index: 2;
    opacity: 0.3;
    width: 33.3%;
    min-width: 33.3%;
    max-width: 33.3%;
    transition: 1s;
}
.Comp_Spotlight .gallery__item:nth-child(2) .img_example{
    width: auto;
    height: 100%;
    pointer-events: none;
}

.Comp_Spotlight .gallery__item:nth-child(3) {
    left: 50%;
    z-index: 4;
    transform: translateX(-50%) scale(1);
    width: auto;
    transition: 1s;
}
.Comp_Spotlight .gallery__item:nth-child(3) .div_show_color{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.Comp_Spotlight .gallery__item:nth-child(3) .div_color_clip{
    width: 50%;
    background-color: #0000002b;

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.Comp_Spotlight .gallery__item:nth-child(3) .div_color_blur{    
    backdrop-filter: blur(2px);
    background-color: rgb(255 255 255 / 10%);
    border-left: 1px solid rgb(197 197 197 / 20%);

    animation-duration: 1s;
    animation-name: div_color_blur;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;

    position: absolute;
    right: 0;
    height: 100%;
}
@keyframes div_color_blur {
    0% {
        width: 0%;
    }
    30% {
        width: 0%;
    }

    100% {
        width: 50%;
    }
}
.div_name_img {
    display: none;
}
.Comp_Spotlight .gallery__item:nth-child(3) .div_name_img{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}
.Comp_Spotlight .gallery__item:nth-child(3) .img_example{
    width: auto;
    height: 100%;
    pointer-events: none;
}
.Comp_Spotlight .gallery__item:nth-child(3) .div_name_img .name_project{
    transform: rotate(270deg);
    font-family: fantasy;
    line-height: 90px;
    letter-spacing: 0px;
    font-weight: 400;

    position: relative;
    font-size: 66px;
    color: rgb(255 255 255 / 0%);
    -webkit-text-stroke: 0px #777777;
    text-transform: uppercase;
    white-space: nowrap;
}
.Comp_Spotlight .gallery__item:nth-child(3) .div_name_img .name_project::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    color: #ffffff;
    -webkit-text-stroke: 0vw #777777;

    animation-duration: 2s;
    animation-name: name_project_before;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    overflow: hidden;    
}
@keyframes name_project_before {
    0%, 50%{
        width: 0%;
        border-right: 2px solid #ffffff;
    }
    99%{
        border-right: 2px solid #ffffff;
    }
    100%{
        width: 100%;
    }
}

.Comp_Spotlight .gallery__item:nth-child(4) {
    left: 66.6%;
    z-index: 2;
    opacity: 0.3;
    width: 33.3%;
    min-width: 33.3%;
    max-width: 33.3%;
    transition: 1s;
}
.Comp_Spotlight .gallery__item:nth-child(4) .img_example{
    width: auto;
    height: 100%;
    pointer-events: none;
}
.Comp_Spotlight .gallery__item:nth-child(4) .div_show_color{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Comp_Spotlight .gallery__item:nth-child(n+5) {
    left: 100%;
    z-index: 1;
    opacity: 0.3;
    width: 33.3%;
    min-width: 33.3%;
    max-width: 33.3%;
    transition: 1s;
}
.Comp_Spotlight .gallery__item:nth-child(n+5) .img_example{
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    transition: 1s;
}

.div_color_blur_hide {
    animation-duration: 3s;
    animation-name: div_color_blur_hide;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;

    position: absolute;
    right: 0;
    height: 100%;
}
@keyframes div_color_blur_hide {
    0% {
        width: 50%;
    }
    30% {
        width: 50%;
    }

    100% {
        width: 0%;
    }
}
.div_name_img .name_project_hide{
    transform: rotate(270deg);
    font-family: 'Staatliches';
    line-height: 90px;
    letter-spacing: 0px;
    font-weight: 400;

    position: relative;
    font-size: 90px;
    color: rgb(255 255 255 / 0%);
    -webkit-text-stroke: 0px #777777;
    text-transform: uppercase;
    white-space: nowrap;
}
.div_name_img .name_project_hide::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    color: #ffffff;
    -webkit-text-stroke: 0vw #777777;

    animation-duration: 3s;
    animation-name: name_project_before_hide;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;

    overflow: hidden;    
}
@keyframes name_project_before_hide {
    0%{
        border-right: 2px solid #ffffff;
        width: 100%;
    }
    100%{
        width: 0%;
    }
}

.btn_gallery {
    display: none;
}
.Comp_Spotlight .gallery__item:nth-child(3) .btn_gallery{
    z-index: 3;
    height: 100%;
    position: absolute;
    right: 0;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end;
    padding-right: 30px;

    animation-duration: 2s;
    animation-name: btn_gallery;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;

    overflow: hidden;  
    white-space: nowrap;
}
@keyframes btn_gallery {
    0%{
        width: 0%;
    }
    50% {
        width: 0%;
    }
    100%{
        width: 50%;
    }
}
.Comp_Spotlight .gallery__item:nth-child(3) .btn_gallery .title_btn{
    text-align: right;
    line-height: 20px;
    letter-spacing: 0px;
    font-weight: 300;
    font-size: 15px;
}
.Comp_Spotlight .gallery__item:nth-child(3) .btn_gallery .show_btn{
    cursor: pointer;
    text-align: left;
    line-height: 30px;
    letter-spacing: 0px;
    font-weight: 400;
    font-size: 15px;
    border-style: solid;
    border-width: 1px;
    padding-left: 30px;
    padding-right: 30px;
    margin: 20px 0px 30px;
}


/* Controllers */
.Comp_Spotlight .gallery__prev, .Comp_Spotlight .gallery__next {
    position: absolute;
    top: 50%;
    z-index: 7;
    height: 100%;
    cursor: pointer;
}
.Comp_Spotlight .gallery__prev {
    transform: translateX(50%) translateY(-50%) scale(.8);
}
.Comp_Spotlight .gallery__next {
    transform: translateX(-50%) translateY(-50%) scale(.8);
}
.Comp_Spotlight .gallery__prev {
    left: 0;
}
.Comp_Spotlight .gallery__next {
    right: 0;
}
.Comp_Spotlight .img_{
    width: 100%;
}
.Comp_Spotlight .icons_carousel_prev{
    width: -webkit-fill-available;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.Comp_Spotlight .icons_carousel_next{
    width: -webkit-fill-available;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.Comp_Spotlight .icons_galerry{
    width: 42px;
    height: 42px;
    display: block;
    background-color: rgb(0 0 0 / 50%);
    border-radius: 50%;
}
.Comp_Spotlight .icons_galerry_next{
    transform: rotate(180deg);
}
/* end */


@media only screen and (max-width: 1080px) {
    .Comp_Spotlight .gallery__item{
        width: 80%;
    }   
}
@media only screen and (max-width: 850px) {
    .Comp_Spotlight .show_spotlight, .Comp_Spotlight .div_carousel{
        min-height: auto;
    }
    
}
@media only screen and (max-width: 670px) {
    .Comp_Spotlight .div_carousel{
        position: relative;
        display: flex;
        flex-direction: column;
    }
}
@media only screen and (max-width: 460px) {
    .Comp_Spotlight .title{
        font-size: 30px;
    }
    .Comp_Spotlight .gallery{
        min-width: -webkit-fill-available;
    }
    .Comp_Spotlight .gallery__item:nth-child(1), .Comp_Spotlight .gallery__item:nth-child(2), .Comp_Spotlight .gallery__item:nth-child(4){
        width: auto;
    }

    .Comp_Spotlight .gallery__item:nth-child(3){
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Comp_Spotlight .show_gallery_data{
        height: 100%;
    }
    .Comp_Spotlight .gallery__item:nth-child(3) .div_name_img .name_project{
        font-size: 64px;
    }

    .Comp_Spotlight .gallery__item:nth-child(3) .img_example{
        width: 100%;
    }
}