.Page_Preview{
    width: 100%;
    height: auto;
    position: relative;
}

.Page_Preview .efect_translate_y{
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
.Page_Preview .efect_translate_visible {
    opacity: 1;
    transform: translateY(0);
}

.Page_Preview .div_initial{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
}
.Page_Preview .contents{
    height: auto;
    margin: 0 auto;
}
.Page_Preview .div_initial .img_full{
    top: 0px;
    background-size: cover;
    width: 100%;
    height: 95vh;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: -999;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    z-index: 1;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.Page_Preview .name_project{
    color: #ededed;
}


/* div line color 25% */
.Page_Preview .div_line{
    width: 100%;
    height: 95vh;
    position: relative;
    top: 0;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
.Page_Preview .div_line .line{
    width: 25%;
    background-color: #121212;
}
.Page_Preview .div_line .bg_1{
    height: 17%;
}
.Page_Preview .div_line .bg_2{
    height: 7%;
}
.Page_Preview .div_line .bg_3{
    height: 2%;
}
.Page_Preview .div_line .bg_4{
    height: 22%;
}
/* end */


/* div_data_project */
.Page_Preview .div_data_project{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding: 24px 0px;
}
.Page_Preview .div_data_project .div_data{
    flex-grow: 1;
    width: 50%;
    min-height: 160px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    gap: 30px;
    color: #ededed;
}
.Page_Preview .div_data_project .show_data_project{
    align-items: flex-start;
}
.Page_Preview .div_data_project .align_top{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
}
.Page_Preview .div_data_project .div_data .data_project{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.Page_Preview .div_data_project .show_data_project .data_project{
    align-items: flex-start;
}
.Page_Preview .div_data_project .align_top .data_client{
    width: 50%;
    justify-content: flex-start;
    align-items: flex-start;
}
.Page_Preview .div_data_project .div_data .data_project .title{
    font-size: 100px;
    font-weight: 600;
    line-height: 88px;
    text-align: left;
}
.Page_Preview .div_data_project .div_data .data_project .subtitle{
    font-size: 36px;
    font-weight: 200;
}
.Page_Preview .div_data_project .div_data .data_project .name_cliente {
    color: #54595F;
    font-size: 15px;
    text-transform: uppercase;
    margin: 8px 0px;
    letter-spacing: 1px;
}
.Page_Preview .div_data_project .div_data .text_project{
    text-align: left;
    font-size: 21px;
    font-weight: 300;
    line-height: 32px;
    color: #ededed;
    font-style: normal;
    letter-spacing: 1px;
    font-family: 'Archivo', sans-serif;
    margin-left: 5%;
}
.Page_Preview .div_data_project .div_data .show_text {
    width: 50%;
}
/* end */


/* contents */
.Page_Preview .show_data_page{
    width: 100%;
    max-width: 1140px;
    margin: 0% auto;
    height: auto;
    position: relative;

    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 35px;
    margin-bottom: 35px;

    overflow: hidden;
}
.Page_Preview .page_full{
    max-width: none;
}
.Page_Preview .show_data_page .div_contents{
    width: calc(100% - 20px);
    height: auto;
    padding: 0px 10px;
}
.Page_Preview .show_data_page .div_contents .show_img{
    width: 100%;
    max-width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    border: none;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
}

.Page_Preview .show_data_page .div_contents .div_text{
    width: 950px;
    margin: 0% auto;
    padding: 10px;
    color: #ededed;
}
.Page_Preview .show_data_page .div_contents .div_text .title{
    font-size: 13px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.49);
}
.Page_Preview .show_data_page .div_contents .div_text .text{
    width: 100%;
    font-size: 24px;
    line-height: 40px;
    margin-left: -1px;
    text-align: left;
    font-weight: 300;
    font-style: normal;
    transition-delay: 0s;
}
/* end */


.Page_Preview .datasheet{
    text-align: right;
    width: 100%;
    max-width: 1140px;
    margin: 0% auto;
    height: auto;
    position: relative;
    display: flex;
    justify-content: flex-end !important;
    flex-direction: row;
    margin-top: 35px;
    margin-bottom: 35px;
}
.Page_Preview .datasheet .text_project{
    text-align: left;
    font-size: 16px;
    line-height: 36px;
    width: 50%;
    color: #ededed;
}

.Page_Preview .break_line{
    display: none;
}

.Page_Preview .Comp_Footer .div_img .img{
    top: 50%;
    transform: translate(-50%, -50%);
}


@media only screen and (max-width: 1200px) {
    .Page_Preview .div_data_project{
        flex-direction: column;
        width: 96%;
        max-width: 1140px;
    }
    .Page_Preview .div_data_project .div_data{
        min-height: auto;
        width: calc(100% - 40px);
        margin: 0px 20px;
    }
    .Page_Preview .div_data_project .div_data .data_project{
        width: calc(100% - 40px);
        padding: 0px 20px;
        align-items: flex-start;
    }
    .Page_Preview .div_data_project .div_data .text_project{
        width: calc(100% - 40px);
        padding: 0px 20px;
        align-items: flex-start;
    }
}
@media only screen and (max-width: 850px) {
    .Page_Preview .div_line .bg_1, .Page_Preview .div_line .bg_2{
        width: 50%;
    }
    .Page_Preview .div_line .bg_3, .Page_Preview .div_line .bg_4{
        display: none;
    }
    .Page_Preview .show_data_page{
        width: 90%;
    }
}
@media only screen and (max-width: 700px) {
    .Page_Preview .div_data_project .div_data{
        flex-direction: column;
    }
    .Page_Preview .div_data_project .div_data .text_project{
        margin-left: 0;
    }
}
@media only screen and (max-width: 640px) {
    .Page_Preview .div_data_project .div_data .data_project .title{
        font-size: 60px;
        line-height: 60px;
    }
    .Page_Preview .show_data_page{
        flex-direction: column;
        gap: 35px;
    }
    .Page_Preview .datasheet{
        justify-content: center;
    }
    .Page_Preview .datasheet .text_project{
        width: calc(100% - 60px);
        padding: 0px 30px;
    }
}


@media only screen and (max-width: 992px) {
    .Page_Preview .show_data_page .div_contents .div_text {
        max-width: 790px;
    }
}
@media only screen and (max-width: 768px) {
    .Page_Preview .show_data_page .div_contents .div_text {
        max-width: 88%;
    }
}
@media only screen and (max-width: 576px) {
    .Page_Preview .show_data_page .div_contents .div_text {
        max-width: 90%;
    }
    .Page_Preview .div_data_project .align_top .data_client{
        font-size: 10px;
        gap: 10px;
        padding: 0;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .Page_Preview .div_data_project .div_data .data_project .title{
        text-align: left;
    }
    .Page_Preview .div_data_project .div_data .data_project{
        width: 100%;
        padding: 0;
        justify-content: space-between;
    }
    .Page_Preview .div_data_project .div_data .data_project .name_cliente{
        display: flex;
        flex-direction: column;
        font-size: 10px;
    }
}