.Page_Contact{
    width: 80%;
    height: auto;
    margin: 0% auto;
    display: flex;
    flex-direction: row;
    gap: 20px;
    color: #ffffff;
    overflow: hidden;

    animation-duration: 3s;
    animation-name: Page_Contact;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;

    padding: 80px 0;
}
@keyframes Page_Contact {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.Page_Contact .list_form{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Page_Contact .list_form form{
    width: 80%;
}
.Page_Contact .list_form .div_form{
    padding: 20px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: auto;
    background-color: rgb(86 86 86);
}
.Page_Contact .list_form .div_form .div_input .textarea{
    resize: none;
    height: 80px !important;
    min-height: 80px !important;
}
.Page_Contact .list_form .div_form .div_input .btn_save{
    width: 100%;
    border: transparent;
    padding: 10px;
    border-radius: 6px;
    font-size: 16px;
    background-color: #7c9f06;
    color: #ffffff;
    cursor: pointer;
}
.Page_Contact .list_form .div_form .div_input .btn_save:hover {
    background-color: #5a7305;
    transform: scale(0.98);
}

.Page_Contact input, .Page_Contact select, .Page_Contact textarea{
    border: 2px solid #ebebeb;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px;
    outline: none;
    width: -webkit-fill-available;
    background-color: #ffffff;
}

/* form */
.Page_Contact .contents .form {
    background: #0b0b0b;
    padding: 80px 40px;
    margin-top: 80px;
}
.Page_Contact .contents .form .div_input{
    position: relative;
    margin-bottom: 75px;
    width: 100%;
    display: block;
    border-bottom: 1px solid rgba(187, 187, 187, .3);
    display: flex;
    flex-direction: row;
}
.Page_Contact .contents .form .div_input .show_input{
    border: transparent;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px;
    outline: none;
    width: -webkit-fill-available;
    background-color: transparent;
    color: #ffffff;
    resize:none;
}
.Page_Contact .contents .form .div_input textarea {
    min-height: 22px;
}
.Page_Contact .contents .form .name_input{
    width: 30%;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 20px 20px;
    color: #ededed;
}
.Page_Contact .contents .form .show_div_input{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 20px 0px;
    width: calc(100% - 40px);
}

.Page_Contact .contents .form .div_form_submit{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Page_Contact .contents .form .div_form_submit .div_checkbox{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 10px;
}
.Page_Contact .contents .form .div_form_submit .div_checkbox .show_div_input{
    padding: 0;
}
.Page_Contact .contents .form .div_form_submit .div_checkbox .name_input{
    width: 150px;
    padding: 0;
}
.Page_Contact .contents .form .div_form_submit .btn{
    border: none;
    background: none;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
    display: block;
    padding: 0;
    color: #ededed;
    border-bottom: 1px solid #ededed;
    width: max-content;
    cursor: pointer;
}
/* end */

@media only screen and (max-width: 1000px) {
    .Page_Contact{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
    }
    .Page_Contact .list_form{
        width: 100%;
        height: auto;
        justify-content: flex-start;
    }
    .Page_Contact .list_form form{
        width: 100%;
    }
}
@media only screen and (max-width: 560px) {
    .Page_Contact .contents .form{
        padding: 20px;
    }
    .Page_Contact .contents .form .div_input{
        flex-direction: column;
        margin-bottom: 40px;
    }
    .Page_Contact .contents .form .name_input{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0px 10px;
    }
    .Page_Contact .contents .form .show_div_input{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@media only screen and (max-width: 510px) {
    .Page_Contact{
        gap: 30px;
    }
    .Page_Contact .list_video .show_video{
        height: auto;
        width: 100%;
    }
}
