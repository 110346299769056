.Comp_Text{
    width: 100%;
    height: calc(100% - 300px);
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #ffffff;
    overflow: hidden;
    font-family: 'Archivo', sans-serif;

    animation-duration: 3s;
    animation-name: Comp_Text;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;

    padding: 150px 0px;
}
@keyframes Comp_Text {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.Comp_Text .efect_translate_y{
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
.Comp_Text .efect_translate_visible {
    opacity: 1;
    transform: translateY(0);
}

.Comp_Text .div_text{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
.Comp_Text .div_text .show_text{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.Comp_Text .div_text .show_text .subtitle{
    font-weight: 100;
    letter-spacing: 2px;
    font-size: 13px;
    text-transform: uppercase;
    padding: 0px 15px;
    color: rgba(255, 255, 255, 0.49);

    margin-bottom: 30px;
}
.Comp_Text .div_text .show_text .title{
    font-size: 100px;
    font-style: normal;
    font-weight: 600;
    line-height: 110px;
    margin-bottom: 69px;
    color: #ededed;
    padding: 0px 15px;
}
.Comp_Text .div_text .show_text .div_show_text{
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
}
.Comp_Text .div_text .show_text .new_align_text{
    align-items: flex-start;
}
.Comp_Text .div_text .show_text .text{
    text-align: left;
    width: 60%;
    color: #ededed;
    font-size: 21px;
    line-height: 35px;
    font-weight: 300;
    padding: 0px 15px;
}
.Comp_Text .div_text .show_text .new_text_css{
    font-size: 30px;
    line-height: 50px;
    margin-left: -1px;
    font-weight: 400;
}

.Comp_Text .list_video{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Comp_Text .list_video .show_video{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 576px){
    .Comp_Text .div_text {
        max-width: 90%;
    }
}

@media only screen and (min-width: 768px){
    .Comp_Text .div_text {
        max-width: 80%;
    }
}

@media only screen and (min-width: 992px){
    .Comp_Text .div_text {
        max-width: 790px;
    }
}

@media only screen and (min-width: 1200px){
    .Comp_Text .div_text {
        max-width: 950px;
    }
}


@media only screen and (max-width: 850px) {
    .Comp_Text .div_text{
        width: 100%;
        max-width: 100%
    }
    .Comp_Text .div_text .show_text .title{
        font-size: 55px;
        line-height: 70px;
        margin-bottom: 35px;
    }
    .Comp_Text .div_text .show_text .div_show_text{
        align-items: flex-start;
    }
}
@media only screen and (max-width: 560px) {
    .Comp_Text{
        width: 100%;
        height: calc(100% - 160px);
        padding: 80px 0px;
    }
    .Comp_Text .div_text{
        margin-bottom: 0;
    }
    .Comp_Text .div_text .show_text .text{
        width: calc(100% - 30px);
    }
}