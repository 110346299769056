.Comp_Clients{
    width: 100%;
    height: calc(100% - 150px);
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #ffffff;
    overflow: hidden;
    font-family: 'Archivo', sans-serif;

    animation-duration: 3s;
    animation-name: Comp_Clients;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;

    padding-top: 150px;
}
@keyframes Comp_Clients {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.Comp_Clients .efect_translate_y, .List_All_Logo .efect_translate_y{
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
.Comp_Clients .efect_translate_visible, .List_All_Logo .efect_translate_visible {
    opacity: 1;
    transform: translateY(0);
}

.Comp_Clients .div_text{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 70px;
}
.Comp_Clients .div_text .show_text{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.Comp_Clients .div_text .show_text .subtitle{
    font-weight: 100;
    letter-spacing: 2px;
    font-size: 13px;
    text-transform: uppercase;
    padding: 0px 15px;
    color: rgba(255, 255, 255, 0.49);

    margin-bottom: 30px;
}
.Comp_Clients .div_text .show_text .title{
    font-size: 100px;
    font-style: normal;
    font-weight: 600;
    line-height: 110px;
    margin-bottom: 69px;
    color: #ededed;
    padding: 0px 15px;
}
.Comp_Clients .div_text .show_text .div_show_text{
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
}
.Comp_Clients .div_text .show_text .text{
    text-align: left;
    width: 60%;
    color: #ededed;
    font-size: 21px;
    line-height: 35px;
    font-weight: 300;
    padding: 0px 15px;
}


.Comp_Clients .list_video{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Comp_Clients .list_video .show_video{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 576px){
    .Comp_Clients .div_text {
        max-width: 90%;
    }
}

@media only screen and (min-width: 768px){
    .Comp_Clients .div_text {
        max-width: 80%;
    }
}

@media only screen and (min-width: 992px){
    .Comp_Clients .div_text {
        max-width: 790px;
    }
}

@media only screen and (min-width: 1200px){
    .Comp_Clients .div_text {
        max-width: 950px;
    }
}


@media only screen and (max-width: 850px) {
    .Comp_Clients .div_text{
        width: 100%;
        max-width: 100%
    }
    .Comp_Clients .div_text .show_text .title{
        font-size: 55px;
        line-height: 70px;
        margin-bottom: 35px;
    }
    .Comp_Clients .div_text .show_text .div_show_text{
        align-items: flex-start;
    }
}
@media only screen and (max-width: 560px) {
    .Comp_Clients{
        width: 100%;
        height: calc(100% - 160px);
        padding: 80px 0px;
    }
    .Comp_Clients .div_text{
        margin-bottom: 0;
    }
    .Comp_Clients .div_text .show_text .text{
        width: calc(100% - 30px);
    }
}

.List_All_Logo{
    width: 100%;
    height: auto;
    margin: 0% auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: #ffffff;
    overflow: hidden;

    animation-duration: 3s;
    animation-name: Section_OurServices;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;

    padding: 80px 0px;
}
.List_All_Logo .line_serv{
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.List_All_Logo .line_serv .div_logo_client{
    width: 160px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.List_All_Logo .line_serv .div_logo_client .logo_client{
    width: 100%;
    height: 100%;
    opacity: 0.5;
}
.List_All_Logo .line_serv .div_logo_client .logo_client:hover{
    opacity: 1;
}

@media only screen and (max-width: 850px) {    
    .List_All_Logo{
        flex-direction: column;
        padding-top: 0px;
    }
    .List_All_Logo .line_serv{
        width: 100%;
    }
    .List_All_Logo .line_serv .div_logo_client{
        width: 250px;
    }
}