.Comp_ShowNumbers {
    width: 100%;
    height: calc(100% - 300px);
    display: flex;
    flex-direction: column;
    position: relative;
    color: #ffffff;
    overflow: hidden;

    animation-duration: 3s;
    animation-name: Comp_ShowNumbers;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    padding: 150px 0px;
}
@keyframes Comp_ShowNumbers {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.Comp_ShowNumbers .div_text{
    width: 80%;
    height: 100%;
    margin: 0% auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}
.Comp_ShowNumbers .div_text .show_text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    max-width: 90%;
}
.Comp_ShowNumbers .div_text .show_text .title{
    font-size: 64px;
    font-weight: 800;
    line-height: 84px;
    margin-bottom: 0;
    text-transform: none;
}
.Comp_ShowNumbers .div_text .show_text .text{
    font-weight: 600;
    font-style: normal;
    font-size: 30px;
    line-height: 35px;
}


.Comp_ShowNumbers .div_number{
    width: 80%;
    height: 100%;
    margin: 0% auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 0% auto;
    gap: 20px;
}
.Comp_ShowNumbers .div_number .year{
    width: 320px;
    min-width: 320px;
    max-width: 320px;
    height: 340px;
    min-height: 340px;
    max-height: 340px;
    border: 1px solid #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Comp_ShowNumbers .div_number .year .number_year{
    color: #bfbfbf;
    font-size: 130px;
    font-weight: 700;
    font-family: sans-serif;
}
.Comp_ShowNumbers .div_number .sigla{
    position: relative;
    top: -6px;
}
.Comp_ShowNumbers .div_number .number{
    font-size: 75px;
    font-weight: 500;
    color: rgb(191, 191, 191);
}
.Comp_ShowNumbers .div_number .name{
    text-align: center;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-family: sans-serif;
    line-height: 2.5;
}
.Comp_ShowNumbers .div_number .list_number{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: -webkit-fill-available;
    flex-grow: 1;
}
.Comp_ShowNumbers .div_number .list_number .show_number{
    width: 300px;
    height: 160px;
    flex-grow: 1;
    background-color: rgb(30, 30, 30);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Comp_ShowNumbers .div_number .list_number .show_number .div_data{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 1200px) {
    .Comp_ShowNumbers .div_number .list_number .show_number{
        width: 44%;
    }
}
@media only screen and (max-width: 1110px) {
    .Comp_ShowNumbers .div_number .sigla{
        line-height: 64px;
    }
    .Comp_ShowNumbers .div_number .number{
        line-height: 64px;
    }
}

@media only screen and (max-width: 1000px) {
    .Comp_ShowNumbers .div_number .list_number{
        width: 100%;
    }
    .Comp_ShowNumbers .div_number .year{
        width: 230px;
        max-width: 230px;
        min-width: 230px;
    }
}

@media only screen and (max-width: 992px) {
    .Comp_ShowNumbers .div_text{
        padding: 0;
        margin: 0% auto;
        justify-content: center;
    }
    .Comp_ShowNumbers .div_text .show_text{
        gap: 10px;
    }
    .Comp_ShowNumbers .div_text .show_text .title{
        font-size: 46px;
    }
    .Comp_ShowNumbers .div_text .show_text .text{
        font-size: 14px;
        line-height: 24px;
    }

    .Comp_ShowNumbers .div_text{
        height: 30%;
    }
    .Comp_ShowNumbers .div_number .list_number .show_number{
        width: 290px;
        height: 80px;
    }
    .Comp_ShowNumbers .div_number .year{
        height: 376px;
        min-height: 376px;
        max-height: 376px;
    }
    .Comp_ShowNumbers .div_number .number{
        font-size: 46px;
        line-height: 46px
    }
}

@media only screen and (max-width: 690px) {
    .Comp_ShowNumbers .div_number{
        flex-direction: column;
        gap: 20px;
    }
    .Comp_ShowNumbers .div_number .year{
        height: -webkit-fill-available;
        min-height: -webkit-fill-available;
        max-height: -webkit-fill-available;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 20px 0px;
    }
    .Comp_ShowNumbers .div_number .year .number_year{
        line-height: normal;
    }
    .Comp_ShowNumbers .div_number .list_number{
        align-content: center;
        flex-grow: inherit;
    }
    .Comp_ShowNumbers .div_number .list_number .show_number{
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 20px 0px;
    }
    .Comp_ShowNumbers .div_text .show_text .title{
        line-height: 56px;
    }
}