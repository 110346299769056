.Comp_OurServices{
    width: 80%;
    height: auto;
    margin: 0% auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: #ffffff;
    overflow: hidden;

    animation-duration: 3s;
    animation-name: Comp_OurServices;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;

    padding: 150px 0px;
    z-index: 1;
}
@keyframes Comp_OurServices {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.Comp_OurServices .contents{
    width: 950px;
    display: flex;
    flex-direction: column;
}

.Comp_OurServices .efect_translate_y{
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
.Comp_OurServices .efect_translate_visible {
    opacity: 1;
    transform: translateY(0);
}

.Comp_OurServices .div_text{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.Comp_OurServices .div_text .show_text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
    width: 100%;
}
.Comp_OurServices .div_text .show_text .subtitle{
    font-size: 13px;
    text-transform: uppercase;
    padding: 0px 15px;
    color: rgba(255, 255, 255, 0.49);
}
.Comp_OurServices .div_text .show_text .title{
    color: #ededed;
    font-size: 100px;
    font-style: normal;
    font-weight: 600;
    line-height: 110px;
    padding: 0px 15px;
}

.Comp_OurServices .show_list_btn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.Comp_OurServices .list_btn{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.Comp_OurServices .list_btn .text{
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
    padding: 0px 15px;
}
.Comp_OurServices .list_btn .div_btn{
    width: calc(100% - 30px);
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
}
.Comp_OurServices .list_btn .btn_space_top {
    margin-top: 1em;
}
.Comp_OurServices .list_btn .div_btn .show_btn{
    color: #ededed;
    /* background-color: #1B1B1B; */
    cursor: pointer;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    padding-bottom: 20px;
    /* max-width: 400px; */
}
.Comp_OurServices .list_btn .div_btn .show_btn .number_item{
    margin-right: 20px;
    font-size: 16px;
    vertical-align: middle;
    color: #7C7C7C;
}
.Comp_OurServices .list_btn .div_btn .show_btn .arrow_btn .arrow_down{
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    transition: .5s;
}
.Comp_OurServices .list_btn .div_btn .show_text{
    font-size: 15px;
    line-height: 27px;
    padding-left: 39px;
    opacity: 0;
    transition: 1s;
    /* background-color: #101010; */
    /* max-width: 400px; */
    cursor: default;
}
.Comp_OurServices .list_btn .div_btn .text_open{
    opacity: 1;
    padding-left: 39px;
    color: #ededed;
}

/* @media only screen and (max-width: 760px) {
    .Comp_OurServices{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    .Comp_OurServices .div_text{
        width: 100%;
        padding: 0;
        margin: 0% auto;
        justify-content: center;
    }
    .Comp_OurServices .list_btn{
        width: 100%;
    }
    .Comp_OurServices .div_text .show_text{
        gap: 10px;
    }
    .Comp_OurServices .div_text .show_text .title{
        font-size: 55px;
        line-height: 55px
    }
    .Comp_OurServices .div_text .show_text .text{
        font-size: 14px;
    }
    .Comp_OurServices .list_btn .div_btn{
        width: 100%;
    }
    .Comp_OurServices .list_btn .div_btn .show_btn, .Comp_OurServices .list_btn .div_btn .text_open{
        margin: 0% auto;
        max-width: inherit;
    }
    .Comp_OurServices .list_btn .div_btn .show_btn{
        width: calc(100% - 32px);
        padding: 10px 16px;
    }
    .Comp_OurServices .list_btn .div_btn .show_text{
        min-width: auto;
    }
    .Comp_OurServices .list_btn, .Comp_OurServices .div_text{
        height: auto;
    }
} */
@media only screen and (max-width: 560px) {
    .Comp_OurServices{
        gap: 30px;
        padding: 35px 0;
    }
    .Comp_OurServices .div_text .show_text .title{
        font-size: 32px;
        line-height: 46px;
    }
    .Comp_OurServices .contents{
        gap: 30px;
        width: 100%;
    }
}


@media only screen and (min-width: 576px) {
    .Comp_OurServices {
        max-width: 90%;
    }
}

@media only screen and (min-width: 768px) {
    .Comp_OurServices {
        max-width: 80%;
    }
}

@media only screen and (min-width: 992px) {
    .Comp_OurServices {
        max-width: 790px;
    }
}

@media only screen and (min-width: 1200px) {
    .Comp_OurServices {
        max-width: 950px;
    }
}


@media only screen and (max-width: 850px) {
    .Comp_OurServices{
        width: 100%;
        max-width: 100%;
    }
    .Comp_OurServices .contents{
        flex-direction: row;
    }
    .Comp_OurServices .div_text .show_text{
        gap: 20px;
    }
    .Comp_OurServices .div_text .show_text .title{
        font-size: 55px;
        line-height: 70px;
        margin-bottom: 35px;
    }
    .Comp_OurServices .show_list_btn{
        justify-content: flex-start;
        width: 50%;
    }
    .Comp_OurServices .list_btn{
        width: calc(100% - 30px);
        padding: 0px 15px;
    }
    .Comp_OurServices .list_btn .text{
        padding: 0;
    }
}

@media only screen and (max-width: 580px) {
    .Comp_OurServices .contents{
        display: flex;
        flex-direction: column;
    }
    .Comp_OurServices .show_list_btn{
        width: 100%;
    }
    .Comp_OurServices .list_btn .text{
        font-size: 24px;
        line-height: 40px;
    }
    .Comp_OurServices .div_text{
        width: 100%;
    }
    .Comp_OurServices .list_btn .div_btn{
        width: calc(100% - 15px);
        padding-right: 15px;
    }
    .Comp_OurServices .list_btn .div_btn .text_open{
        width: calc(100% - 54px);
        padding-left: 54px;
    }
}