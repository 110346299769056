

/* mask fixed */
.div_mask{
    width: 100%;
    display: flex;
    flex-direction: row;
    position: absolute;
    z-index: 10;
}
.mask_top{
    align-items: flex-start;
    top: 0;
}
.mask_bottom{
    align-items: flex-end;
    bottom: 0;
}
.div_mask .mask_fixed{
    width: 25%;
}
.div_mask .mask_1{
    height: 90px;
}
.div_mask .mask_2{
    height: 40px;
}
.div_mask .mask_3{
    height: 70px;
}
.div_mask .mask_4{
    height: 20px;
}
@media only screen and (max-width: 850px) {
    .div_mask .mask_1, .div_mask .mask_2 {
        width: 50%;
    }
    .div_mask .mask_3, .div_mask .mask_4 {
        display: none;
    }
}
/* end */