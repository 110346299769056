.Comp_Portfolio {
    width: 100%;
    height: calc(100% - 150px);
    display: flex;
    flex-direction: column;
    position: relative;
    color: #ffffff;
    overflow: hidden;
    font-family: 'Archivo', sans-serif;

    animation-duration: 3s;
    animation-name: Comp_Portfolio;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    padding-top: 150px;
    padding-bottom: 50px;
}
@keyframes Comp_Portfolio {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.Comp_Portfolio .efect_translate_y{
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
.Comp_Portfolio .efect_translate_visible {
    opacity: 1;
    transform: translateY(0);
}

.Comp_Portfolio .div_title{
    width: 80%;
    margin: 0% auto;
    padding-bottom: 60px;
    text-transform: uppercase;
}
.Comp_Portfolio .div_title .title_page{
    letter-spacing: 2px;
    font-size: 13px;
}
.Comp_Portfolio .div_title .subtitle_page{
    font-weight: 600;
    font-style: normal;
    font-size: 47px;
    line-height: 65px;
}

.Comp_Portfolio .list_portfolio {
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    height: auto; */
    width: 98%;
    margin: 0% auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 10px;
}
.Comp_Portfolio .list_portfolio .div_show_portfolio{
    /* width: auto;
    height: 570px;
    transition: 0.5s;
    scale: 0.9;
    flex-grow: 1; */
    grid-column: span 1;
    transform: scale(1);
    transition: all .4s ease-in-out;
}
.Comp_Portfolio .list_portfolio .div_show_portfolio:hover{
    transform: scale(1.1);
    z-index: 2;
}
.Comp_Portfolio .list_portfolio .div_show_portfolio .img_{
    width: 100%;
    height: auto;
    display: flex;
}
.Comp_Portfolio .list_portfolio .div_show_portfolio .img_ .show_img{
    width: 100%;
    height: 100%;
}
.Comp_Portfolio .list_portfolio .div_show_portfolio .show_name{
    width: calc(100% - 32px);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 16px;
    background-color: #1a1a1a;
}
.Comp_Portfolio .list_portfolio .div_show_portfolio .name{
    font-family: Sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 1px;
}
.Comp_Portfolio .list_portfolio .div_show_portfolio .name_detalis{
    font-family: Sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #737373;
}

.Comp_Portfolio .div_btn{
    width: 100%;
    height: auto;
    padding: 50px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Comp_Portfolio .div_btn .name_btn{
    font-family: Sans-serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 2px;
    color: #ffffff;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #FFFFFF;
    width: 400px;
    height: 70px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.Comp_Portfolio .div_btn .name_btn:hover {
    animation-duration: 1.1s;
    animation-name: name_btn;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
}
@keyframes name_btn {
    0%{
        color: #ffffff;
        border-width: 1px 1px 1px 1px;
    }
    100%{
        background-color: #ffffff;
        color: #000000;
    }
}
@media only screen and (max-width: 560px) {
    .Comp_Portfolio {
        padding-bottom: 0px;
    }
    .Comp_Portfolio .list_portfolio{
        justify-items: center;
    }
    .Comp_Portfolio .list_portfolio .div_show_portfolio{
        width: 96%;
    }
    .Comp_Portfolio .div_btn{
        width: 90%;
        margin: 0% auto;
    }
}